import styled from '@emotion/styled';

export const StyledPanel = styled.div<{ backgroundColor?: string }>(
    ({ theme, backgroundColor }) => ({
        backgroundColor,
        position: 'absolute',
        top: `calc(100% + ${theme.spaces[1]})`,
        left: 0,
        right: 0,
        zIndex: theme.zIndices[2],
        // This fixes an issue on iOS where the list changes z-index when typing.
        transform: 'translateZ(0px)',
        '> div': {
            borderRadius: theme.borders.radius,
            border: `1px solid ${theme.colors.dark50}`,
        },
    })
);

export const StyledListItem = styled.li<{ shade?: 'light' | 'dark' }>(({ theme, shade }) => ({
    padding: `${theme.spaces[2]} ${theme.spaces[3]}`,
    ['mark']: {
        background: 'none',
        fontWeight: theme.fontWeights.bold,
        color: 'inherit',
    },
    ['&[aria-selected="true"]']: {
        background: shade === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
    },
    cursor: 'pointer',
}));
