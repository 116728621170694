import styled from '@emotion/styled';

export const StyledSearchRoot = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices[1],
}));

export const StyledSearchFormRoot = styled.div(() => ({
    position: 'relative',
}));
