import create from 'zustand';
import { SearchState } from '../../productsList/models';

export const useSearchState = create<SearchState>((set) => ({
    query: undefined,
    setQuery: (query?: string) => {
        set(() => ({
            query,
        }));
    },
}));
