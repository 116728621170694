import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { indicatePending } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export type StyledFormGridProps = {
    columns?: string;
    pending?: boolean;
};

export type StyledFormColumnProps = {
    column?: string;
    columnSm?: string;
    columnMd?: string;
    columnLg?: string;
    rightAlign?: boolean;
};

export const StyledFormGrid = styled.form<StyledFormGridProps>(
    ({ theme, columns = '6' }) => ({
        display: 'grid',
        gap: theme.spaces[3],
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
    }),
    ifProp('pending', {
        ...indicatePending,
    })
);

export const StyledFormColumn = styled.div<StyledFormColumnProps>(
    ({ column = '1 / span 6' }) => ({
        gridColumn: column,
        minWidth: 80,
    }),
    ifProp('columnSm', ({ columnSm }) => ({
        [breakpoints.sm]: {
            gridColumn: columnSm,
        },
    })),
    ifProp('columnMd', ({ columnMd }) => ({
        [breakpoints.md]: {
            gridColumn: columnMd,
        },
    })),
    ifProp('columnLg', ({ columnLg }) => ({
        [breakpoints.lg]: {
            gridColumn: columnLg,
        },
    })),
    ifProp('rightAlign', () => ({
        textAlign: 'right',
    }))
);
