import React, { useCallback, useEffect, useRef } from 'react';
import { Panel, useAutoComplete } from '~/features/search';
import { Form } from '~/shared/utils';
import { StyledSearchFormRoot, StyledSearchRoot } from './styled';
import { SearchField } from '../SearchField';
import { useSearchRouting } from '~/features/navigation';
import { useSearchState } from '~/features/search/hooks/useSearchState';

export const Search = () => {
    const { autocomplete, ariaLabelId, selectedParams } = useAutoComplete();
    const ref = useRef<HTMLInputElement | null>(null);
    const { submitToSearch } = useSearchRouting();
    const { setQuery } = useSearchState();

    const formProps = autocomplete.getFormProps({
        inputElement: ref.current,
    });
    const rootProps = autocomplete.getRootProps({});

    const closePanel = useCallback(() => {
        autocomplete.setIsOpen(false);
    }, [autocomplete]);

    useEffect(() => {
        const domRef = ref.current;
        domRef?.addEventListener('blur', closePanel);
        return () => domRef?.removeEventListener('blur', closePanel);
    }, [ref, closePanel]);

    useEffect(() => {
        if (selectedParams?.itemInputValue) {
            setQuery(selectedParams?.itemInputValue);
            submitToSearch(selectedParams?.itemInputValue);
        }
    }, [selectedParams, setQuery, submitToSearch]);

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        ref.current?.blur();
        setQuery(ref.current?.value);
        setTimeout(() => {
            submitToSearch(ref.current?.value ?? '');
        });
    };

    return (
        <StyledSearchRoot {...rootProps} aria-labelledby={ariaLabelId}>
            <Form.Root {...formProps} onSubmit={(e) => onFormSubmit(e)}>
                <Form.Column>
                    <StyledSearchFormRoot style={{ position: 'relative' }}>
                        <SearchField ref={ref} />
                        <Panel />
                    </StyledSearchFormRoot>
                </Form.Column>
            </Form.Root>
        </StyledSearchRoot>
    );
};
