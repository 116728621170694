import React, { useMemo } from 'react';

import { M210SearchbarModule } from '~/lib/data-contract';
import { Gutter, RawHtml, SearchSuggestions } from '~/shared/components';
import { StyledM210, StyledM210Content } from './styled';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { Spacer, useThemeShade } from '~/theme';
import { useSession } from 'next-auth/react';
import { Search } from './components/Search';
import { AutoComplete } from '~/features/search';
import { getStyledElement } from '~/shared/utils/jsx/utils/styled-element';

export type M200ProfileProps = M210SearchbarModule;
const defaultBackgroundColor = '#ffffff';
export const M210Searchbar = ({
    description,
    backgroundColor = defaultBackgroundColor,
    spacingBottom,
    spacingTop,
    ...rest
}: M200ProfileProps) => {
    const session = useSession();
    const userName = session.data?.user.userName;
    const userNameCapitalized = useMemo(
        () =>
            getStyledElement({
                text: userName?.toLowerCase(),
                style: { textTransform: 'capitalize' },
            }),
        [userName]
    );
    const { backgroundColor: backgroundColorShade } = useThemeShade({
        backgroundColor: backgroundColor ?? defaultBackgroundColor,
    });

    return (
        <ModuleContainer
            spacingBottom={spacingBottom}
            spacingTop={spacingTop}
            backgroundColor={backgroundColorShade}
            hasGutter={false}
        >
            <StyledM210>
                <StyledM210Content>
                    <Gutter>
                        <RawHtml
                            html={description}
                            interpolation={{ userName: userNameCapitalized }}
                            backgroundColor={backgroundColor}
                        />
                        <Spacer />
                        <AutoComplete ariaLabelId="search-module-autocomplete" {...rest}>
                            <Search />
                        </AutoComplete>
                    </Gutter>
                    <SearchSuggestions
                        gutter={true}
                        initialBackgroundColor={backgroundColorShade}
                    />
                </StyledM210Content>
            </StyledM210>
        </ModuleContainer>
    );
};
