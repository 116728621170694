import { StyledFormGrid, StyledFormColumn } from './styled';

/**
 * Used to render a form layout using display grid
 * used in coherence with Form.Column.
 *
 * Defaults to a 6 columns grid which can be overridden with the 'columns' prop
 * Likewise the column span defaults to '1 / span 6' but can also be overridden.
 * @example
 * <Form.Root>
 *      <Form.Column column="1 / span 6">
 *          <Input />
 *      </Form.Column>
 *     <Form.Column column="1 / span 2">
 *          <Input />
 *      </Form.Column>
 *      <Form.Column column="3 / span 4" columnMd="1 / span 6">
 *          <Input />
 *      </Form.Column>
 * </Form.Root>
 */
export const Form = {
    Root: StyledFormGrid,
    Column: StyledFormColumn,
};
