import React, { FormEvent, KeyboardEvent, MouseEvent } from 'react';
import { AutocompleteState } from '@algolia/autocomplete-js';
import { AutocompleteApi, OnSelectParams } from '@algolia/autocomplete-core';
import { AutoSuggestion, M210SearchbarModule } from '~/lib/data-contract';

export type AutocompleteContextType = {
    autocomplete: AutocompleteApi<AutoSuggestion, FormEvent, MouseEvent, KeyboardEvent>;
    ariaLabelId: string;
    selectedParams?: OnSelectParams<AutoSuggestion>;
    state?: AutocompleteState<AutoSuggestion>;
} & Pick<M210SearchbarModule, 'placeholder' | 'keywords'>;

export const AutoCompleteContext = React.createContext<AutocompleteContextType>(
    {} as AutocompleteContextType
);
