import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { searchReset } from '~/shared/utils/styled/searchReset';
import { Button } from '~/shared/components/Button';

export const StyledSearchContainer = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
});

export const StyledInput = styled.input<{ shade?: 'light' | 'dark' }>(
    ({ theme: { spaces, fontSizesFluid, fontSizes, colors, borders }, shade = 'dark' }) => ({
        backgroundColor: shade === 'dark' ? colors.dark70 : colors.light30,
        color: shade === 'dark' ? colors.light : colors.dark,
        border: shade === 'dark' ? 'none' : borders.border,
        width: '100%',
        height: 52,
        minWidth: spaces[7],
        fontSize: fontSizes.md,
        padding: `${spaces[2]} ${spaces[6]} ${spaces[2]} ${spaces[3]}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: borders.radius,
        paddingRight: spaces[7],

        ...searchReset,
        '::placeholder': {
            color: shade === 'dark' ? colors.light30 : colors.dark50,
        },
        [breakpoints.md]: {
            fontSize: fontSizesFluid.md,
        },
    })
);

export const StyledSearchButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    right: 2,
    borderRadius: theme.borders.radius,
}));
