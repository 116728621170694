import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { StyledSearchContainer, StyledInput, StyledSearchButton } from './styled';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';
import { useTranslation } from '~/shared/utils';
import Loupe from '$icons/search.svg';
import { useThemeShade } from '~/theme';
import { useTheme } from '@emotion/react';
import { useAutoComplete } from '~/features/search';
import { useTypewriter } from '~/shared/hooks';

type Props = {
    backgroundColor?: string;
};

export const SearchField = forwardRef<HTMLInputElement, Props>(
    ({ backgroundColor, ...restProps }: Props, ref) => {
        const { translate } = useTranslation();
        const inputRef = useRef<HTMLInputElement | null>(null);
        const { traits } = useTheme();
        const { autocomplete, ariaLabelId, placeholder, keywords } = useAutoComplete();
        const { backgroundShade: shade } = useThemeShade({
            backgroundColor: backgroundColor || traits.background.default,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const inputProps = autocomplete.getInputProps({
            inputElement: inputRef.current,
        });

        const { text: typewriterText } = useTypewriter({
            inputText: placeholder,
            variableTextArray: keywords,
            // Set delay to null to disable placeholder typewriter effect, when input has value
            ...(inputProps.value?.length ? { letterDelay: null } : {}),
        });

        return (
            <StyledSearchContainer>
                <StyledInput
                    shade={shade}
                    ref={inputRef}
                    aria-label={translate('header.search.placeholder')}
                    {...inputProps}
                    {...restProps}
                    placeholder={typewriterText || inputProps.placeholder}
                    id={ariaLabelId}
                    aria-labelledby={ariaLabelId}
                />
                <StyledSearchButton shape="icon" shade={shade} showHoverIndicator={false}>
                    <Loupe aria-hidden="true" />
                    <VisuallyHidden children={translate('accessibility.search')} />
                </StyledSearchButton>
            </StyledSearchContainer>
        );
    }
);
