import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchHook } from '~/features/navigation/hooks/search/useSearchHook';
import { useFrame } from '~/shared/utils';

/**
 * This hook handles the gentle logic of when and where to route when search is used
 * It has a dependency to Algolia through the useSearchHook, being on the
 * search page it let's algolia handle the URL and search logic, otherwise it will route
 *
 * @returns
 */
export const useSearchRouting = () => {
    const { search } = useSearchHook(350);
    const router = useRouter();
    const { query } = router.query;
    const { data: frame } = useFrame();
    const searchPageUrl = frame?.staticLinks?.searchPage?.url || '';
    const shouldRoute = useRef(!router.asPath.includes(searchPageUrl));

    const searchPageRouteConnector = async (searchQuery: string) => {
        if (!shouldRoute.current && searchQuery !== '') {
            search(searchQuery);
            return;
        }

        await router.push({
            pathname: searchPageUrl,
            query: {
                query: decodeURIComponent(searchQuery),
            },
        });
    };

    const submitToSearch = useCallback(
        (query: string) => {
            router.push({ pathname: searchPageUrl, query: { query: decodeURIComponent(query) } });
        },
        [router, searchPageUrl]
    );

    const routingStateHandler = (url: string) => {
        shouldRoute.current = !url.includes(searchPageUrl);
    };

    useEffect(() => {
        router.events.on('routeChangeStart', routingStateHandler);
        router.events.on('routeChangeComplete', routingStateHandler);

        return () => {
            router.events.off('routeChangeStart', routingStateHandler);
            router.events.off('routeChangeComplete', routingStateHandler);
        };
    });

    return {
        searchPageRouteConnector,
        isRouting: shouldRoute.current,
        searchQuery: typeof query === 'string' ? query : '',
        submitToSearch,
    };
};
