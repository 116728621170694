import React from 'react';
import { useAutoComplete } from '~/features/search';
import parse from 'html-react-parser';
import { useTheme } from '@emotion/react';
import { useThemeShade } from '~/theme';
import { StyledListItem, StyledPanel } from './styled';
import { Text } from '~/shared/components';

type Props = {
    backgroundColor?: string;
};

export const Panel = ({ backgroundColor: backgroundColorProp }: Props) => {
    const { traits } = useTheme();
    const { backgroundColor, textShade, readableColorShade } = useThemeShade({
        backgroundColor: backgroundColorProp || traits.background.default,
    });
    const { autocomplete, state } = useAutoComplete();
    const panelProps = autocomplete.getPanelProps({});

    return (
        <StyledPanel backgroundColor={backgroundColor} {...panelProps}>
            {state?.isOpen &&
                state.collections.map(({ source, items }, index) => (
                    <div key={`source-${index}`}>
                        {items.length > 0 && (
                            <ul {...autocomplete.getListProps()}>
                                {items.map((item) => (
                                    <StyledListItem
                                        key={item.objectID}
                                        shade={readableColorShade}
                                        {...autocomplete.getItemProps({
                                            item,
                                            source,
                                        })}
                                    >
                                        <Text shade={textShade}>
                                            {item._highlightResult.query.value
                                                ? parse(item._highlightResult.query.value)
                                                : item.query}
                                        </Text>
                                    </StyledListItem>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
        </StyledPanel>
    );
};
