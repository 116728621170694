import { CSSObject, keyframes } from '@emotion/react';

const keyframeAnimation = keyframes`
    0%,
    100% {
        opacity: 0.75;
    }
    50% {
        opacity: 0.4;
    }
`;

/**
 * Makes content pulsate after a short while
 * indicating a pending state
 */
export const indicatePending: CSSObject = {
    pointerEvents: 'none',
    animation: `1.5s ${keyframeAnimation} 0.25s forwards infinite;`,
};
