import { CSSObject } from '@emotion/react';

/**
 * Hides the browser default buttons for input type search
 */
export const searchReset: CSSObject = {
    ['&::-ms-clear, &::-ms-reveal']: {
        display: 'none',
        width: 0,
        height: 0,
    },
    ['&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration']: {
        display: 'none',
    },
};
