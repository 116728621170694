import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

export type UseTypeWriterProps = {
    /**
     * The text on which a typewriter effect is desired
     */
    inputText?: string;
    /**
     * Text at the end of inputText, that can be variable
     */
    variableTextArray?: string[];
    /**
     * delay between each letter, default = 100ms. Set to null to pause effect
     */
    letterDelay?: number | null;
    /**
     * The pause between each variable text string
     */
    variableTextPause?: number;
};

export const useTypewriter = ({
    inputText,
    variableTextArray,
    letterDelay = 100,
    variableTextPause = 1000,
}: UseTypeWriterProps) => {
    const [text, setText] = useState('');
    const isDeletingRef = useRef(false);
    const [enabled, setEnabled] = useState(!!inputText);
    const intervalTimeRef = useRef(enabled ? letterDelay : null);
    const activeVariableIndexRef = useRef(0);

    useInterval(
        () => {
            if (!(enabled && inputText && letterDelay)) {
                return;
            }
            // If we are deleting, and the text is equal to inputText, we know we have reached the point where
            //  we should stop deleting, and start adding the next variableText
            if (text === inputText && isDeletingRef.current) {
                isDeletingRef.current = false;
                activeVariableIndexRef.current =
                    (activeVariableIndexRef.current + 1) % (variableTextArray || []).length;
                return;
            }
            const completeText =
                inputText +
                (variableTextArray?.length
                    ? ` ${variableTextArray?.[activeVariableIndexRef.current]}`
                    : '');
            const letterIndex = text.length + (isDeletingRef.current ? -1 : 1);
            const letterSpeed = isDeletingRef.current ? letterDelay / 3 : letterDelay;

            // If smaller than text, we know we don't show all letters yet, and can continue typewriter effect.
            // If equal to text, we know we are 1 letter away from finishing. We finish the text, then set interval time to variableTextPause.
            // If there are multiple variabletext elements, we set to delete, so the next word can be written
            // else disable effect, as we are now done
            if (letterIndex < completeText.length) {
                setText(completeText.slice(0, letterIndex));
                intervalTimeRef.current = letterSpeed;
            } else if (letterIndex === completeText.length) {
                setText(completeText.slice(0, letterIndex));
                intervalTimeRef.current = variableTextPause;
            } else if (variableTextArray && variableTextArray.length > 1) {
                isDeletingRef.current = true;
            } else {
                // We need a state update, otherwise intervalTimeRef.current is stale
                setEnabled(false);
            }
        },
        enabled ? intervalTimeRef.current : null
    );

    useEffect(() => {
        intervalTimeRef.current = inputText ? letterDelay : null;
        setEnabled(!!inputText);
    }, [inputText, letterDelay]);

    return { text, setText };
};
