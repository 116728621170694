import styled from '@emotion/styled';
import { InputField } from '~/shared/components';
import { breakpoints, getClampFullRange } from '~/theme';

export const StyledM210 = styled.div(({ theme }) => ({
    padding: `${getClampFullRange(theme.spacesRaw[4], theme.spacesRaw[8])} 0 ${getClampFullRange(
        theme.spacesRaw[5],
        theme.spacesRaw[7]
    )}`,
    display: 'grid',
    gap: theme.gridConfig.gap,
    gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.xs}, 1fr)`,
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
    },
}));

export const StyledM210Content = styled.div({
    gridColumn: '1 / -1',
    [breakpoints.sm]: {
        gridColumn: '2 / span 4',
    },
    [breakpoints.md]: {
        gridColumn: '2 / span 5',
    },
});

export const StyledSearchField = styled(InputField)(({ theme }) => ({
    borderRadius: theme.borders.radius,
}));
