import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { useSearchState } from '~/features/search/hooks/useSearchState';

export const useSearchHook = (debounceMs?: number) => {
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const searchState = useSearchState();

    const clearSearch = useCallback(() => {
        searchState.setQuery(undefined);
        setSearchValue(undefined);
    }, [searchState]);

    const search = useCallback(
        (query?: string) => {
            if (!query) {
                clearSearch();
                return;
            }

            searchState.setQuery(query);
        },
        [clearSearch, searchState]
    );

    useDebounce(
        () => {
            search(searchValue);
        },
        debounceMs,
        [searchValue]
    );

    return {
        search: setSearchValue,
        searchValue,
        clearSearch,
        ...searchState,
    };
};
